import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CONFIG, AUTH_GATEWAY } from '../../../config/api.config';
import { StorageService } from '../services/auth/storage.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    private toastService: MessageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.toastService.clear();
    const localUser = this.storage.getLocalUser();

    const reqToAPI = this.isReqToApi(req.url, API_CONFIG.baseURL);
    const reqToAuthAPI = this.isReqToApi(req.url, AUTH_GATEWAY.baseURL);

    if (localUser && (reqToAPI || reqToAuthAPI)) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localUser.token),
      });
      return next.handle(authReq);
    } else {
      return next.handle(req);
    }
  }

  isReqToApi(url: string, apiUrl: string) {
    const N = apiUrl.length;
    const reqToApi = url.substring(0, N) === apiUrl;

    return reqToApi;
  }
}

export const authInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
