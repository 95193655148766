import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPipe } from './utils/empty.pipe';
import { CurrencyRealPipe } from './currency-real-pipe/currency-real.pipe';

@NgModule({
  declarations: [CurrencyRealPipe, EmptyPipe],
  imports: [CommonModule],
  exports: [CurrencyRealPipe, EmptyPipe],
})
export class PipeModule {}
