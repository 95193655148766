import { Component, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import { datadogRum } from '@datadog/browser-rum';

import { PreferencesService } from './shared/services/preferences/preferences.service';
import { ThemeService } from './shared/services/theme/theme.service';
import { AuthService } from './shared/services/auth/auth.service';
import { LoaderService } from './shared/services/app-loader/loader.service';
import { VERSION } from './../config/app.config';
import { ApiService } from './shared/services/api/api.service';
import { environment } from './../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  isDarkTheme = false;
  appNickName = 'Reembolsa Aí';
  appVersion = VERSION;
  apiVersion = 'desconhecida';
  ambiente = environment.environment;

  public appPages = [
    {
      title: 'Home',
      url: '/historico-pedidos',
      icon: 'home',
    },
    {
      title: 'Solicitar Reembolso',
      url: '/solicitar-reembolso',
      icon: 'assets/icon/ipp/p3.svg',
    },
    {
      title: 'Solicitar Adiantamento',
      url: '/solicitar-adiantamento',
      icon: 'assets/icon/ipp/p6.svg',
    },
    // {title: 'Prestação de Conta', url: '/prestacao-contas',  icon: 'assets/icon/ipp/p7.svg',},
    {
      title: 'Rascunhos',
      url: '/rascunho',
      icon: 'document',
    },
    {
      title: 'Configurar Ativos',
      url: '/ativos',
      icon: 'car-sport',
    },
    {
      title: 'Fale Conosco',
      url: '/form-contato',
      icon: 'chatbox-ellipses',
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private readonly preferencesService: PreferencesService,
    private readonly themeService: ThemeService,
    private readonly auth: AuthService,
    private readonly apiService: ApiService,
    public readonly loaderService: LoaderService,
  ) {
    this.enableDataDog();
    this.loadApiInfo();
    this.loadPreferences();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  loadPreferences() {
    const sub = this.preferencesService.getThemePreference().subscribe(
      (data) => {
        const { theme } = data;
        document.body.setAttribute('color-theme', theme);

        if (theme === 'DARK') {
          this.isDarkTheme = true;
          this.themeService.switchTheme('bootstrap4-dark-yellow');
          document.body.setAttribute('color-theme', 'DARK');
          this.preferencesService.currentTheme =
            'assets/imgs/ipp/logo-i-dark.png';
        } else {
          this.isDarkTheme = false;
          this.themeService.switchTheme('saga-blue');
          this.preferencesService.currentTheme = 'assets/imgs/ipp/logo-i.png';
        }
      },
      (error) => {
        document.body.setAttribute('color-theme', 'LIGHT');
        this.preferencesService.currentTheme = 'assets/imgs/ipp/logo-i.png';
        this.isDarkTheme = false;
        this.themeService.switchTheme('saga-blue');
      }
    );
    this.subscriptions.add(sub);
  }

  loadApiInfo() {
    this.apiService.getApiInfo().subscribe((data) => {
      this.apiVersion = data.versao;
    });
  }

  toggleDetails(p) {
    if (p.showDetails) {
      p.showDetails = false;
      p.icon = 'ios-arrow-down';
    } else {
      p.showDetails = true;
      p.icon = 'ios-arrow-up';
    }
  }

  toggleThemes(event) {
    const { checked } = event.detail;
    if (checked) {
      document.body.setAttribute('color-theme', 'DARK');
      this.themeService.switchTheme('bootstrap4-dark-yellow');
      this.preferencesService.setThemePreference('DARK');
    } else {
      document.body.setAttribute('color-theme', 'LIGHT');
      this.preferencesService.setThemePreference('LIGHT');
      this.themeService.switchTheme('saga-blue');
    }
  }

  logout() {
    this.auth.logout();
  }

  isProduction() {
    return environment.environment === 'PRODUCTION' ? true : false;
  }

  getCurrentLogo() {
    return this.preferencesService.currentLogo;
  }

  enableDataDog() {
    datadogRum.init({
      applicationId: '09fd9cd4-1fef-464d-8f39-5a03c84f1593',
      clientToken: 'pub76c614b596e84754c84a9df6fe95591b',
      site: 'datadoghq.com',
      service: 'reembolsa-ai',
      env: 'dev',
      // Specify a version number to identify the deployed version of your application in Datadog
      version: VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}
