import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from 'src/config/storageKeys.config';
import { AtivoDTO } from '../../interfaces/Ativo.dto';
import LocalUserDTO from '../../interfaces/user/LocalUser.sto';
import { RascunhoDTO } from '../../interfaces/rascunho/Rascunho.dto';
import { UserDetailDTO } from '../../interfaces/user/UserDetailDTO';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  getLocalUser(): LocalUserDTO {
    const user = localStorage.getItem(STORAGE_KEYS.localUser);
    if (user == null) {
      return null;
    } else {
      return JSON.parse(user);
    }
  }

  setLocalUser(obj: LocalUserDTO) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.localUser);
    } else {
      localStorage.setItem(STORAGE_KEYS.localUser, JSON.stringify(obj));
    }
  }

  setAppVersion(version: string) {
    localStorage.setItem(STORAGE_KEYS.appVersion, version);
  }
  
  getAppVersion(): string {
    return localStorage.getItem(STORAGE_KEYS.appVersion);
  }

  getUserDetail(): UserDetailDTO {
    try {
      const user: LocalUserDTO = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.localUser)
      );

      return user ? user.userInformations : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  getUserAtivos(): AtivoDTO[] {
    const user = localStorage.getItem(STORAGE_KEYS.userAtivos);
    return user ? JSON.parse(user) : null;
  }

  setUserAtivos(obj: AtivoDTO[]) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.userAtivos);
    } else {
      localStorage.setItem(STORAGE_KEYS.userAtivos, JSON.stringify(obj));
    }
  }

  getUseRascunhos(): RascunhoDTO[] {
    const user = localStorage.getItem(STORAGE_KEYS.userRaschunhos);
    return user ? JSON.parse(user) : null;
  }

  setUserRascunhos(obj: RascunhoDTO[]) {
    if (obj == null) {
      localStorage.removeItem(STORAGE_KEYS.userRaschunhos);
    } else {
      localStorage.setItem(STORAGE_KEYS.userRaschunhos, JSON.stringify(obj));
    }
  }

  isLogedIn() {
    const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.localUser));
    if (user) {
      return true;
    } else {
      return false;
    }
  }

  isLogedInObs(): Observable<boolean> {
    const user = JSON.parse(localStorage.getItem(STORAGE_KEYS.localUser));
    if (user) {
      return of(true);
    } else {
      return of(false);
    }
  }

  getRole(role: string): boolean {
    const localUser: LocalUserDTO = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.localUser)
    ) as LocalUserDTO;
    const { permissions } = localUser.roles;

    const permissionIndex = permissions.findIndex((p) => p.value == role);

    if (permissionIndex <= -1)
      throw new Error(`Role ${role} não encontrada na lista de permissões`);

    const { canActive } = permissions[permissionIndex];

    return canActive;
  }

  getUserIsAdmin(): boolean {
    const localUser: LocalUserDTO = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.localUser)
    ) as LocalUserDTO;
    const { isAdmin } = localUser.roles;

    return isAdmin;
  }
}
