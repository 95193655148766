export const environment = {
  production: false,
  environment: 'DEVELOPMENT',
  dataDogEnv: 'dev',
  dataDogApplicationId: '09fd9cd4-1fef-464d-8f39-5a03c84f1593',
  apiconfig: {
    // baseUrl: 'http://localhost:3334/pedido-compra',
    baseUrl: 'https://api-sandbox.ipirangacloud.com/mobile/dev/pedido-compra',
  },
  authgateway: {
    baseUrl: 'https://api-sandbox.ipirangacloud.com/mobile/dev/auth-gateway',
  },
  maxFileSize: 9.5,
  cameraResolution: 75,
};
