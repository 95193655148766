import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { MessageService, ConfirmationService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { authInterceptorProvider } from './shared/interceptors/auth-interceptor';
import { errorInterceptorProvider } from './shared/interceptors/error-interceptor';
import { PipeModule } from './shared/pipes/pipe.module';
import { DirectivesModule } from './shared/directive/directives.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ToastModule,
    PipeModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    DirectivesModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MessageService,
    ConfirmationService,
    authInterceptorProvider,
    errorInterceptorProvider,
  ],
  bootstrap: [AppComponent],
  exports: [ToastModule, PipeModule, ProgressBarModule],
})
export class AppModule {}

defineCustomElements(window);

//enableProdMode();
