import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, AUTH_GATEWAY } from 'src/config/api.config';
import CredenciaisDTO from '../../interfaces/Credencias.dto';
import LocalUserDTO from '../../interfaces/user/LocalUser.sto';
import { StorageService } from './storage.service';
import { PreferencesService } from '../preferences/preferences.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private preferencesService: PreferencesService,
    private userService: UserService
  ) {}

  authenticate(credenciais: CredenciaisDTO): Observable<LocalUserDTO> {
    return this.http.post<LocalUserDTO>(
      `${AUTH_GATEWAY.baseURL}/sessions`,
      credenciais,
      {
        responseType: 'json',
      }
    );
  }

  refreshToken() {
    try {
      const token = {
        token: this.storage.getLocalUser().token,
      };

      return this.http.post<any>(
        `${AUTH_GATEWAY.baseURL}/sessions/refresh`,
        token,
        {
          responseType: 'json',
        }
      );
    } catch (e) {
      throw e;
    }
  }

  loginSucesso(logedUser: LocalUserDTO) {
    this.storage.setLocalUser(logedUser);
  }

  logout() {
    this.storage.setLocalUser(null);
  }
}
