import { Injectable } from '@angular/core';
import { AlertController, AlertOptions, AnimationBuilder, LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Alert } from '../../interfaces/Alert';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private customAlert: AlertOptions;

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private readonly alertController: AlertController,
    private readonly loaderController: LoadingController,
  ) { }

  async presentCustomLoader({ cssClass, header, subHeader, message }: Alert) {
    const alert = await this.loaderController.create({
      cssClass,
      message,
      backdropDismiss: false,
      animated: true,
    });

    await alert.present();

    /*
    const alert = await this.alertController.create({
      cssClass,
      header,
      subHeader,
      message,
      backdropDismiss: false,
      animated: true,
     // enterAnimation: this.animation('<body>'),
    });

    await alert.present();
    */
  }

  async changeCustomLoader({ cssClass, header, subHeader, message }: Alert) {
    const alert = await this.loaderController.getTop();
    alert.cssClass = cssClass || alert.cssClass;
    alert.message = message || alert.message;
  }

  async closeCustomLoader() {
    const top = await this.loaderController.getTop();
    if (top) {
      this.loaderController.dismiss();

    }
  }

  loadBlock(): void {
    const isBlock = this.isLoading.getValue();
    const value = isBlock ? 'none' : 'all';
    document.documentElement.style.setProperty('--load-block', value);
  }
}
