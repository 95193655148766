import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_CONFIG } from 'src/config/api.config';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly http: HttpClient
    ) {}

  getApiInfo(): Observable<any> {
    return this.http.get<any>(`${API_CONFIG.baseURL}/`, {
      responseType: 'json',
    });
  }


}
