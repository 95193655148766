import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyRealPipe',
})
export class CurrencyRealPipe implements PipeTransform {
  transform(value: any): string {
    const newValue = Number(value).toFixed(2).replace('.', ',');

    return newValue;
  }
}
