import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../auth/storage.service';
import { UserDetailDTO } from '../../interfaces/user/UserDetailDTO';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  getUserDetail(): UserDetailDTO {
    return this.storage.getUserDetail();
  }
}
