import { API_CONFIG } from 'src/config/api.config';
import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PreferencesDTO } from '../../interfaces/user/PreferencesDTO';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  currentTheme: string;
  currentLogo: string = "assets/imgs/ipp/logo-i.png";
  themeSubscriber = new ReplaySubject();

  constructor(private http: HttpClient) { }

  setThemePreference(themePreference: string): void {
    this.themeSubscriber.next(themePreference);
    this.currentTheme = themePreference;
    if (themePreference == 'DARK') {
      this.currentLogo = "assets/imgs/ipp/logo-i-dark.png";
    } else {
      this.currentLogo = "assets/imgs/ipp/logo-i.png";
    }
    this.updateThemePreference(themePreference).subscribe((response) => { });
  }

  getThemePreference(): Observable<PreferencesDTO> {
    return this.http.get<PreferencesDTO>(`${API_CONFIG.baseURL}/sessions/preferences`, {
      responseType: 'json',
    });
  }

  private updateThemePreference(theme: string): Observable<any> {
    return this.http.put<any>(
      `${API_CONFIG.baseURL}/sessions/preferences`,
      { theme },
      {
        responseType: 'json',
      }
    );
  }
}
