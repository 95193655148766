import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { MessageService } from 'primeng/api';

import { Toast } from '../shared/enumerations/toast.enum';
import { StorageService } from '../shared/services/auth/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private toastService: MessageService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserHasPermission(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.toastService.clear();

    const url = segments.map((s) => `/${s}`).join('');

    return this.checkAuthState(url).pipe(take(1));
  }

  private checkAuthState(redirect: string): Observable<boolean> {
    return this.storageService.isLogedInObs().pipe(
      tap((is) => {
        if (!is) {
          this.presentToast(
            'warn',
            'Não autenticado',
            'Por favor, realize login',
            Toast.mediumDuration
          );

          this.router.navigate(['/login'], { queryParams: { redirect } });
        }
      })
    );
  }

  private checkUserHasPermission(
    route: ActivatedRouteSnapshot,
    url: any
  ): boolean {
    if (this.storageService.isLogedIn()) {
      const isAdmin = this.storageService.getUserIsAdmin();
      if (isAdmin) {
        return true;
      }

      const hasPermission = this.storageService.getRole(route.data.permission);

      if (route.data.permission && !hasPermission) {
        const segment = route.url.map((s) => `/${s}`).join('');

        const originalUrl = this.router.url.includes('redirect')
          ? `/${this.router.url.split('%2F').pop()}`
          : this.router.url;

        const url = originalUrl == segment ? '/home' : originalUrl;

        this.router.navigate([url]);

        this.presentToast(
          'warn',
          'Não autorizado',
          `É preciso da permissão, '${route.data.permission}', para acessar esta funcionalidade!`,
          Toast.mediumDuration
        );

        return false;
      }
      return true;
    }

    this.presentToast(
      'warn',
      'Não autenticado',
      'Por favor, realize login',
      Toast.mediumDuration
    );
    this.router.navigate(['/login']);
    return false;
  }

  private checkUserRole(route: ActivatedRouteSnapshot, url: any): boolean {
    /*
    if (this.storageService.isLogedIn()) {
      const isAdmin = this.storageService.getUserIsAdmin();
      if (isAdmin) {
        return true;
      }

      const userRole = this.storageService.getRole();

      if ((route.data.role && route.data.role.indexOf(userRole) === -1)) {
        this.presentToast('warn', 'Sem permissão', `Apenas ${route.data.role} podem acessar esta funcionalidade!`, Toast.mediumDuration);
        this.router.navigate(['/home']);
        return false;
      }
      return true;
    }

    this.presentToast('warn', 'Não autenticado', 'Por favor, realize login', Toast.mediumDuration);
    this.router.navigate(['/login']);*/
    return false;
  }

  private presentToast(
    severity: string,
    summary: string,
    detail: string,
    life: Toast
  ) {
    this.toastService.clear();
    this.toastService.add({
      severity,
      summary,
      detail,
      life,
    });
  }

}
