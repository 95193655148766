/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'solicitar-reembolso',
    loadChildren: () =>
      import('./pages/solicitar-reembolso/solicitar-reembolso.module').then(
        (m) => m.SolicitarReembolsoPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'solicitar-adiantamento',
    loadChildren: () =>
      import(
        './pages/solicitar-adiantamento/solicitar-adiantamento.module'
      ).then((m) => m.SolicitarAdiantamentoPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'prestacao-contas',
    loadChildren: () =>
      import('./pages/prestacao-contas/prestacao-contas.module').then(
        (m) => m.PrestacaoContasPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'ativos',
    loadChildren: () =>
      import('./pages/ativos/ativos.module').then((m) => m.AtivosPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'rascunho',
    loadChildren: () =>
      import('./pages/rascunho/rascunho.module').then(
        (m) => m.RascunhoPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'historico-pedidos',
    loadChildren: () =>
      import('./pages/historico-pedidos/historico-pedidos.module').then(
        (m) => m.HistoricoPedidosPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'detalhar-pedido',
    loadChildren: () =>
      import('./pages/detalhar-pedido/detalhar-pedido.module').then(
        (m) => m.DetalharPedidoPageModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: 'config-perfil',
    loadChildren: () => import('./pages/config-perfil/config-perfil.module').then(m => m.ConfigPerfilPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'form-contato',
    loadChildren: () => import('./pages/form-contato/form-contato.module').then(m => m.FormContatoPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'changes-log',
    loadChildren: () => import('./pages/changes-log/changes-log.module').then(m => m.ChangesLogPageModule),
    canLoad: [AuthGuard],
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
