import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AlertController } from '@ionic/angular';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, timeout } from 'rxjs/operators';

import { StorageService } from '../services/auth/storage.service';
import ErrorResponseDTO from '../interfaces/ErrorResponse.dto';
import { MessageService } from 'primeng/api';
import { Toast } from '../enumerations/toast.enum';
import { LoaderService } from '../services/app-loader/loader.service';
import { API_CONFIG } from '../../../config/api.config';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private timeoutTiming = 240000;//20000;

  private notShowToastEndpoint = [
    {
      enpoint: 'preferences',
      method: 'GET',
    },
  ];

  private notUseLoadingEndpoint = [
    {
      enpoint: 'scrap',
      method: 'POST',
    },
    {
      enpoint: 'scrap',
      method: 'PUT',
    },
    {
      enpoint: 'scrap',
      method: 'PATCH',
    },
    {
      enpoint: 'order',
      method: 'POST',
    },
    {
      enpoint: 'order',
      method: 'PUT',
    },
    {
      enpoint: 'order',
      method: 'PATCH',
    },
  ];

  constructor(
    public storage: StorageService,
    public alertContr: AlertController,
    private toastService: MessageService,
    private loaderService: LoaderService,
    private readonly auth: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const doisMinutos = 240000;
    this.handleLoadingService(req);
    const timeoutCount =
      req.url.includes('order') //&& req.method.includes('GET')
        ? doisMinutos
        : this.timeoutTiming;
    return (
      next
        .handle(req)
        //Pipe para Erros
        .pipe(
          catchError((response) => {
            const { statusText } = response;
            if (statusText.toUpperCase() === 'UNKNOWN ERROR') {
              return throwError('Timeout');
            }

            let error: ErrorResponseDTO = response.error;
            if (!error.status) {
              try {
                error = {
                  mensagens: error.mensagens,
                  status: response.status,
                };
              } catch (e) {}
            }

            this.loaderService.closeCustomLoader();

            this.handleToastPresentation(req, error);

            return throwError(error);
          })
        )
        //Pipe para Timeout
        .pipe(
          timeout(timeoutCount),
          catchError((response) => {
            if (response !== 'Timeout') {
              return;
            }
            const error: ErrorResponseDTO = {
              mensagens: ['Tempo de espera excedido'],
              status: '408',
            };
            this.handle408(this.listErrors(error));
            return throwError(error);
          })
        )
        //Pipe para loading de requisições
        .pipe(
          finalize(() => {
            this.loaderService.isLoading.next(false);
            this.loaderService.loadBlock();
          })
        ) as any
    );
  }

  handle400(error: string) {
    const autErro = error.indexOf('Falha na autenticação do usuário') !== -1;

    if (autErro) {
      this.presentToast(
        'error',
        '400 - Não autorizado',
        error,
        Toast.mediumDuration
      );
      this.auth.logout();
      this.router.navigate(['/login']);
    } else {
      this.presentToast(
        'warn',
        '400 - Não autorizado',
        error,
        Toast.mediumDuration
      );
    }
  }

  handle401(error: string) {
    this.presentToast(
      'warn',
      '401 - Não autorizado',
      error,
      Toast.mediumDuration
    );
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  handle403(error: string) {
    this.presentToast('warn', '403 - Proibido', error, Toast.mediumDuration);
    //this.storage.setLocalUser(null);
  }

  handle404(error: string) {
    this.presentToast(
      'warn',
      '404 - Não encontrado',
      error,
      Toast.mediumDuration
    );
  }

  handle408(error: string) {
    this.presentToast(
      'error',
      '408 - Request Timeout',
      error,
      Toast.longDuration
    );
  }

  handle422(error: string) {
    this.presentToast(
      'error',
      '422 - Não processável',
      error,
      Toast.longDuration
    );
  }

  handleDefaultError(error: ErrorResponseDTO) {
    const status = error.status;
    let message = this.listErrors(error);

    if (!message) {
      const unMappedError = error as any;
      message = unMappedError.message;
    }

    this.presentToast('error', status.toString(), message, Toast.longDuration);
  }
  private handleLoadingService(req: HttpRequest<any>) {
    const { url, method } = req;
    const endpoint = url.split(API_CONFIG.baseURL).pop();

    this.notUseLoadingEndpoint.find((point) => {
      if (!(endpoint.includes(point.enpoint) && point.method == method)) {
        this.loaderService.isLoading.next(true);
        this.loaderService.loadBlock();
      }
    });
  }

  private handleToastPresentation(
    req: HttpRequest<any>,
    error: ErrorResponseDTO
  ) {
    const { url, method } = req;
    const endpoint = url.split(API_CONFIG.baseURL).pop();

    const point = this.notShowToastEndpoint.find((p) => {
      if (endpoint.includes(p.enpoint) && p.method === method) {
        return p;
      }
    });

    if (!point) {
      switch (Number(error.status)) {
        case 403:
          this.handle403(this.listErrors(error));
          break;

        case 400:
          this.handle400(this.listErrors(error));
          break;

        case 401:
          this.handle401(this.listErrors(error));
          break;

        case 422:
          this.handle422(this.listErrors(error));
          break;

        case 404:
          this.handle404(this.listErrors(error));
          break;

        default:
          this.handleDefaultError(error);
      }
    }
  }

  private listErrors(error: ErrorResponseDTO): string {
    try {
      const { mensagens } = error;

      let s = '';

      for (const m of mensagens) {
        s = s + m;
      }
      return s;
    } catch (e) {
      return undefined;
    }
  }

  private presentToast(
    severity: string,
    summary: string,
    detail: string,
    life: Toast
  ) {
    this.toastService.clear();
    this.toastService.add({
      severity,
      summary,
      detail,
      life,
    });
  }

  private async createAlert(error) {
    let titulo = error.error;
    let message = error.msg;

    if (!titulo) {
      titulo = 'Falha';
    }

    if (!message) {
      message = error.message;
    }

    const alert = await this.alertContr.create({
      header: error.status + ': ' + titulo,
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });

    await alert.present();
  }
}

export const errorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};
